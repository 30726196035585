import create from 'zustand'

export const LANGUAGE = {
  Chinese: 'zh',
  English: 'en',
}

// store the language in the sessions storage in order to persist through page reloads
const getStoredLanguage = () => sessionStorage.getItem('lang') ?? new URLSearchParams(window.location.search).get('lang')
const setStoredLanguage = (lang) => sessionStorage.setItem('lang', lang)

export const useLanguage = create(set => ({
  language: getStoredLanguage() ?? LANGUAGE.English,
  setLanguage: language => {
    set({ language })
    setStoredLanguage(language)
  }
}))

