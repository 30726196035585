import { API_KEY } from '../environment'

// gapi client
let client = undefined

/**
 * Utilizes the CDN 
 * https://apis.google.com/js/api.js
 * for browser client library support
 * 
 * <script src="https://apis.google.com/js/api.js" onload="gapi.load('client', () => gapi.client.load('drive', 'v3'))"></script>
 * 
 * @param {*} callback 
 */
async function googleapisCDNInit() {
  while (!client) {
    client = gapi?.client?.init({
      apiKey: API_KEY,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/translate/v2/rest'],
      scope: 'https://www.googleapis.com/auth/drive.metadata.readonly'
    })
    await new Promise(r => setTimeout(r, 200))
  }
  return client
}

function driveImageListGenerator(folderId, callback) {
  googleapisCDNInit().then(() => gapi.client.drive.files.list({
    q: `'${folderId}' in parents`,
    fields: 'nextPageToken, files(id, name)',
    pageSize: 999,
  })).then(
    response => callback(response),
    error => console.log('error:', error)
  )
}

export const fetchGDriveCarouselImages = (callback) => driveImageListGenerator('17nBbcMkYclN77-He3VHvZcsUQa-LnZTs', callback)

export function driveIdToImageLink(id) {
  return 'https://lh3.googleusercontent.com/d/' + id
  // + '=w500-h500' // you can specify a target width and height here. will come out as less than or equal
}

