import React from "react"
import { Input } from "@material-ui/core"
import { useHistory } from "react-router-dom"

export default function SearchBar() {
    const history = useHistory()

    return (
        <Input
            id="Search"
            placeholder="Search"
            disableUnderline={true}
            style={{
                color: 'white',
                borderBottom: '2px solid white',
                width: '100%'
            }}
            onKeyPress={event => {
                if (event.key == 'Enter') history.push('/explore?search=' + event.target.value)
            }}
        />
    )
}