import { useLanguage } from "./language"

const wordpressURL = 'https://dallasccc.org'

const Headers = {
  Total: 'X-WP-Total',
  TotalPages: 'X-WP-TotalPages',
}

export const POSTS = {
  endpoint: wordpressURL + '/wp-json/wp/v2/posts',
  getById: function (postID) {
    const urlString = this.endpoint + '/' + postID + generateQueryParams()
    return fetch(urlString)
      .then(res => res.json())
      .then(convertData)
  },
  getBySearch: function ({
    page = undefined,
    per_page = undefined,
    categories = undefined,
    sticky = undefined,
    search = undefined,
  }) {
    const queryParamMap = {
      page,
      per_page,
      categories,
      sticky,
      search,
    }
    const urlString = this.endpoint + generateQueryParams(queryParamMap)
    return fetch(urlString)
      .then(async res => [await res.json(), +res.headers.get(Headers.TotalPages)])
      .then(([json, pageCount]) => [json.map(convertData), pageCount])
      .catch(_ => [[], 0])
  }
}

export const PAGES = {
  endpoint: wordpressURL + '/wp-json/wp/v2/pages',
  getById: function (pageID) {
    const urlString = this.endpoint + '/' + pageID + generateQueryParams()
    return fetch(urlString)
      .then(res => res.json())
      .then(convertData)
  },
  getBySearch: function ({
    parent = undefined,
    search = undefined,
  }) {
    const queryParamMap = {
      parent,
      search
    }
    const urlString = this.endpoint + generateQueryParams(queryParamMap)
    return fetch(urlString)
      .then(async res => [await res.json(), +res.headers.get(Headers.TotalPages)])
      .then(([json, pageCount]) => [json.map(convertData), pageCount])
      .catch(_ => [[], 0])
  }
}

export const MEDIA = {
  endpoint: wordpressURL + '/wp-json/wp/v2/media',
  get: function ({ parent }) {
    const queryParamMap = { parent }
    const urlString = this.endpoint + generateQueryParams(queryParamMap)
    return fetch(urlString)
      .then(res => res.json())
  }
}

const convertData = (post) => ({
  id: post.id,
  title: post.title.rendered,
  date: post.date.rendered,
  content: post.content.rendered,
})

const generateQueryParams = (map = {}) => '/?' +
  Object.keys(map)
    .filter(key => map[key])
    .map((key) => key + '=' + map[key])
    .concat('lang=' + useLanguage.getState().language)
    .join('&')