import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import School from "@material-ui/icons/School";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PaletteIcon from '@material-ui/icons/Palette';

import ArtsLogo from "./ServicesIcons/ArtsIcon.jpg";
import HealthLogo from "./ServicesIcons/HealthLogo.jpg"
import EducationLogo from "./ServicesIcons/EducationLogo.jpg"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";

import { LANGUAGE, useLanguage } from 'services/language'

import styles from "assets/jss/material-kit-react/views/landingPageSections/servicesStyle.js";

const useStyles = makeStyles(styles);

const localization = {
  serviceText: {
    [LANGUAGE.Chinese]: "服務項目",
    [LANGUAGE.English]: "Our Services"
  },
  listServices: {
    [LANGUAGE.Chinese]: "服務內容條列如下",
    [LANGUAGE.English]: "These are a list of services",
  },
  artCulture: {
    [LANGUAGE.Chinese]: "藝文",
    [LANGUAGE.English]: "Arts & Culture",
  },
  healthWellness: {
    [LANGUAGE.Chinese]: "健康",
    [LANGUAGE.English]: "Health & Wellness",
  },
  education: {
    [LANGUAGE.Chinese]: "教育",
    [LANGUAGE.English]: "Education",
  },
  learnMore: {
    [LANGUAGE.Chinese]: "更多資訊",
    [LANGUAGE.English]: "Learn More",
  }
}

export default function ProductSection() {
  const classes = useStyles();

  const { language } = useLanguage()

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{localization.serviceText[language]}</h2>
          <h5 className={classes.description}>
            {localization.listServices[language]}
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={localization.artCulture[language]}
              description={<Button
                color="info"
                href="/arts-and-culture"
              >
                {localization.learnMore[language]}
              </Button>}
              icon={PaletteIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={localization.healthWellness[language]}
              description={<Button
                color="info"
                href="/health-and-wellness"
              >
                {localization.learnMore[language]}
              </Button>}
              icon={LocalHospitalIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={localization.education[language]}
              description={<Button
                color="info"
                href="/education"
              >
                {localization.learnMore[language]}
              </Button>}
              icon={School}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
