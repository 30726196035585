import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons

// core components
import Parallax from "components/Parallax/Parallax.js"

import styles from "assets/jss/material-kit-react/views/profilePage.js"
import { useLanguage } from "services/language"
import { POSTS } from "services/wordpress"
import { CardLink } from "components/CardLink/CardLink"
import { Button } from "@material-ui/core"

const useStyles = makeStyles(styles)

export default function EventsPage() {
  const classes = useStyles()

  const { posts, page, setPage, pageCount } = useEventPosts()

  return (
    <div>
      <Parallax small filter image={require("assets/img/Events.JPG").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div>
            <h3>{page}</h3>
            <Button onClick={() => setPage(page => page - 1)} disabled={page <= 1}>Prev</Button>
            <Button onClick={() => setPage(page => page + 1)} disabled={page >= pageCount}>Next</Button>
          </div>
          <section style={sectionStyles}>
            {
              posts.map((post, i) => <CardLink key={i} postID={post.id} title={post.title} />)
            }
          </section>
        </div>
      </div>
    </div>
  )
}

const sectionStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'repeat(1fr)',
  gap: '1rem',
  padding: '2rem',
}

function useEventPosts() {
  const { language } = useLanguage()
  const [posts, setPosts] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    POSTS.getBySearch({ per_page: 5, page })
      .then(([posts, pageCount]) => {
        setPosts(posts)
        setPageCount(pageCount)
      })
      .then(() => setLoaded(true))
  }, [language, page])

  return { posts, setPosts, page, setPage, loaded, pageCount }
}

