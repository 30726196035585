import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { UpcomingEvents } from "./UpcomingEvent";
import styles from "assets/jss/material-kit-react/views/landingPageSections/eventsStyle.js";

import { LANGUAGE, useLanguage } from 'services/language'

const useStyles = makeStyles(styles);

const localization = {
  featuredPrograms: {
    [LANGUAGE.Chinese]: "精選內容",
    [LANGUAGE.English]: "Featured Programs"
  },
}

export default function WorkSection() {
  const classes = useStyles();

  const { language } = useLanguage()

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>{localization.featuredPrograms[language]}</h2>

        </GridItem>
      </GridContainer>
      <GridContainer>
        <UpcomingEvents></UpcomingEvents>
      </GridContainer>
    </div>
  );
}
