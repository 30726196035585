import React from "react"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-kit-react/views/landingPageSections/eventsStyle.js"
import './UpcomingEvent.css'
import { POSTS } from "../../../services/wordpress"
import { LANGUAGE, useLanguage } from "services/language"

const useStyles = makeStyles(styles)

const localization = {
    learnMore: {
        [LANGUAGE.Chinese]: "更多資訊",
        [LANGUAGE.English]: "Learn More"
    },
}

export function UpcomingEvents() {
    const { language } = useLanguage()
    const classes = useStyles()
    const [posts, setPosts] = React.useState([])
    const [pageIndex, setPageIndex] = React.useState(0)

    React.useEffect(() => {
        POSTS.getBySearch({ sticky: 1 }).then(([postData, _]) => {
            setPosts(postData)
            setPageIndex(index => index + 1)
        })
    }, [language])

    return (
        <section style={sectionStyles} >
            {
                posts.map(post => (
                    <Card style={{ width: "20rem" }}>
                        <CardBody className="upcomingpost">
                            <h4 className={classes.cardTitle}>{post.title}</h4>
                            <Button color="info" href={"/event-info?id=" + post.id}>{localization.learnMore[language]}</Button>
                        </CardBody>
                    </Card>
                ))
            }
        </section>
    )
}

const sectionStyles = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'repeat(1fr)',
    gap: '1rem',
    padding: '2rem'
}