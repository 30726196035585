import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons

// core components
import Parallax from "components/Parallax/Parallax.js"
import CustomTabs from "components/CustomTabs/CustomTabs.js"

import styles from "assets/jss/material-kit-react/views/profilePage.js"
import { useLocation } from "react-router-dom"
import { useLanguage } from "services/language"
import { PAGES } from "services/wordpress"
import { PageComponent } from "views/pageutils"

const useStyles = makeStyles(styles)

export const resourcePageID = 4026

export default function ResourcesPage() {
  // watch the url for changes
  const location = useLocation()
  // when a url change happens, grab the page from the query params 
  React.useEffect(() => setTab(+new URLSearchParams(window.location.search).get('tab')), [location])
  // track the page in a useState
  // passes into CustomTabs
  const [tab, setTab] = React.useState(0)

  const [pageData] = useSubPages(resourcePageID)

  const classes = useStyles()

  return (
    <div>
      <Parallax small filter image={require("assets/img/Resources.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)} style={{ background: "#fff" }}>
        <div className={classes.container}>
          <CustomTabs
            tabValue={tab}
            headerColor="danger"
            tabs={pageData}
          >
          </CustomTabs>
        </div>
      </div>
    </div>
  )
}

export function useSubPages(parentID) {
  const { language } = useLanguage()
  const [subPages, setSubPages] = React.useState([])
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    PAGES.getBySearch({ parent: parentID })
      .then(([pages, _]) =>
        pages.map(page => ({
          tabName: page.title,
          tabContent: <PageComponent htmlBody={page.content} />
        }))
      )
      .then(setSubPages)
      .then(() => setLoaded(true))
  }, [language])

  return [subPages, loaded]
}


