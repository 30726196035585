import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'
import { fetchGDriveCarouselImages, driveIdToImageLink } from '../../../../services/google'

export function PhotoCarousel() {
    const [imageSources, setImageSources] = React.useState([])

    React.useEffect(() => {
        fetchGDriveCarouselImages(response => {
            const images = uniqueRandomBounded(response.result.files.length, 4)
                .map(index => response.result.files[index].id)
                .map(driveIdToImageLink)
                .map(url => ({ src: url }))
            setImageSources(images)
        })
    }, [])

    return (
        <Carousel showArrows={true}>
            {imageSources.map((item, i) => <Item key={i} item={item} />)}
        </Carousel>
    )
}

const Item = (props) => <img src={props.item.src} style={{ height: "500px", maxWidth: "100%", borderRadius: '3rem' }} />


function uniqueRandomBounded(max, count) {
    const images = []
    for (let i = 0; i < count; i++) {
        let index = Math.floor(Math.random() * max)
        while (index in images) index = Math.floor(Math.random() * max)
        images.push(index)
    }
    return images
}