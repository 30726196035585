import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Parallax from "components/Parallax/Parallax.js";
import { PageComponent } from "../pageutils.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ContactPage() {
  const classes = useStyles();
  return (
    <div>
      <Parallax small filter image={require("assets/img/Contact.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <PageComponent pageID={121} />
        </div>
      </div>
    </div>
  );
}
