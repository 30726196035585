import React from "react";
import { Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import SearchBar from "components/SearchBar/SearchBar.js";
import LanguageRadio from "components/LanguageRadio/LanguageRadio.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { PAGES } from 'services/wordpress'
import { LANGUAGE, useLanguage } from "services/language"
import { useSubPages, resourcePageID } from "views/ResourcesPage/ResourcesPage"

const useStyles = makeStyles(styles);

const localization = {
  aboutButton: {
    [LANGUAGE.Chinese]: "關於我們",
    [LANGUAGE.English]: "About"
  },
  missionButton: {
    [LANGUAGE.Chinese]: "中心宗旨",
    [LANGUAGE.English]: "Our Mission"
  },
  boardButton: {
    [LANGUAGE.Chinese]: "中心理事",
    [LANGUAGE.English]: "Our Board"
  },
  servicesButton: {
    [LANGUAGE.Chinese]: "中心服務",
    [LANGUAGE.English]: "Our Services"
  },
  sponsorButton: {
    [LANGUAGE.Chinese]: "贊助單位",
    [LANGUAGE.English]: "Our Sponsors"
  },
  awardButton: {
    [LANGUAGE.Chinese]: "中心獎項",
    [LANGUAGE.English]: "Our Awards"
  },
  programButton: {
    [LANGUAGE.Chinese]: "課程活動",
    [LANGUAGE.English]: "Programs"
  },
  supportButton: {
    [LANGUAGE.Chinese]: "支持贊助",
    [LANGUAGE.English]: "Support"
  },
  resourcesButton: {
    [LANGUAGE.Chinese]: "資源訊息",
    [LANGUAGE.English]: "Resources"
  },
  contactButton: {
    [LANGUAGE.Chinese]: "聯絡我們",
    [LANGUAGE.English]: "Contact"
  },

}

export default function HeaderLinks() {
  const history = useHistory()
  const classes = useStyles()
  const { language } = useLanguage()

  const [resourcePages] = useSubPagesData(resourcePageID)

  return (
    <div className={classes.container}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonText={localization.aboutButton[language]}
            onClick={(tab) => history.push(tab.props.url)}
            dropdownList={[
              <DropdownLink label={localization.missionButton[language]} url="/about?page=0" />,
              <DropdownLink label={localization.boardButton[language]} url="/about?page=1" />,
              <DropdownLink label={localization.servicesButton[language]} url="/about?page=2" />,
              <DropdownLink label={localization.sponsorButton[language]} url="/about?page=3" />,
              <DropdownLink label={localization.awardButton[language]} url="/about?page=4" />,
            ]}
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <Button color="transparent" className={classes.navLink} >
            <Link to="/events" style={{ color: 'inherit' }}>{localization.programButton[language]}</Link>
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button color="transparent" className={classes.navLink} >
            <Link to="/support" style={{ color: 'inherit' }}>{localization.supportButton[language]}</Link>
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonText={localization.resourcesButton[language]}
            onClick={(tab) => history.push(tab.props.url)}
            dropdownList={resourcePages.map((pageData, index) => <DropdownLink label={pageData.title} url={"/resources?tab=" + index} />)}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button color="transparent" className={classes.navLink} >
            <Link to="/contact" style={{ color: 'inherit' }}>{localization.contactButton[language]}</Link>
          </Button>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.facebook.com/dallasccc/?ref=page_internal"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem} style={{ paddingLeft: "100px" }}>
          <LanguageRadio />
        </ListItem>
      </List>
      <div style={{ padding: '0 2rem' }}>
        <SearchBar />
      </div>
    </div>
  );
}


function DropdownLink({ label }) {
  return label
}

export function useSubPagesData(parentID) {
  const { language } = useLanguage()
  const [subPages, setSubPages] = React.useState([])

  React.useEffect(() => {
    PAGES.getBySearch({ parent: parentID })
      .then(([pages, _]) => pages)
      .then(setSubPages)
  })

  return [subPages, setSubPages]
}
