import React from "react";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import { Link } from "react-router-dom";

import logo from "assets/img/DCCC_Logo.jpg";


export default function DCCCHeader() {
  return <Header
    color="transparent"
    brand={
      <Link to="/">
        <img
          src={logo}
          width="181"
          height="70"
        >
        </img>
      </Link>
    }
    rightLinks={<HeaderLinks />}
    fixed
    changeColorOnScroll={{
      height: 200,
      color: "white"
    }}
  />
}