import React from 'react';
import create from 'zustand';
import { MEDIA } from '../../services/wordpress';

const sponsorPageID = 3896

const useSponsorImages = create(set => ({
    images: [],
    setImages: images => set({ images })
}))

// inital load of the sponsor images
MEDIA.get({ parent: sponsorPageID })
    .then(json =>
        json.map(media => ({
            source: media.source_url,
            link: media.alt_text
        }))
    )
    .then(images => useSponsorImages.setState({ images }))

export function FooterSponsors() {
    const { images } = useSponsorImages()
    return (
        <section style={styles}>
            {
                images.map(image => 
                <a href={image.link} target="_blank">
                    <img style={{ height: "80px", width: "80px" }} src={image.source} />
                </a>
                    )
            }
        </section>
    )
}

const styles = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: 'repeat(1fr)',
    gap: '1rem',
    padding: '1rem',
}