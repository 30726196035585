import React from "react"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import he from 'he'
import './CardLink.css'

export function CardLink({ postID, title }) {
    const getInfoUrl = () => "/event-info?id=" + postID

    return (
        <Card>
            <CardBody className="post">
                <h5 className="bold">{he.decode(title)}</h5>
                <Button color="info" href={getInfoUrl()}>Learn More</Button>
            </CardBody>
        </Card>
    )
}
