import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = {
  sponsorGrid: {
    height: "300px",
    width: "500px"
  },
  sponsorList: {
    display: "flex",
    height: "30px",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    padding:"15px 0",
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "100px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    margin: "5px"
  },
  sponsorIcon: {
    width: "100px",
    height: "80px"
  },
  sponsorText: {
    textAlign: "center",
    fontSize: "10px",
    height: "20px",
    width: "100px",
    alignself: "flex-end"
  }
};
export default footerStyle;
