import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Parallax from "components/Parallax/Parallax.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { PageComponent } from "../pageutils.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);


export default function AboutPage() {
  // watch the url for changes
  const location = useLocation()
  // when a url change happens, grab the page from the query params 

  React.useEffect(() => setPage(+new URLSearchParams(window.location.search).get('page')), [location])
  // track the page in a useState
  // passes into CustomTabs
  const [page, setPage] = React.useState(0);

  const classes = useStyles();

  return (
    <div>
      <Parallax small filter image={require("assets/img/About1.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)} style={{ background: "#fff" }}>
        <div className={classes.container}>
          <CustomTabs
            tabValue={page}
            headerColor="danger"
            tabs={[
              {
                tabName: "About Us",
                tabContent: (
                  <PageComponent pageID={32} />
                )
              },
              {
                tabName: "Board of Directors",
                tabContent: (
                  <PageComponent pageID={93} />
                )
              },
              {
                tabName: "Our Services",
                tabContent: (
                  <PageComponent pageID={4069} />
                )
              },
              {
                tabName: "Our Sponsors",
                tabContent: (
                  <PageComponent pageID={4131} />
                )
              },
              {
                tabName: "DCCC Awards",
                tabContent: (
                  <PageComponent pageID={97} />
                )
              }
            ]}
          >
          </CustomTabs>
        </div>
      </div>
    </div>
  );
}
