import React from "react";
import { POSTS } from "../../../services/wordpress";
import { CardLink } from "../../../components/CardLink/CardLink"

export function ServiceEvents(props) {
    const [posts, setPosts] = React.useState([])
    const [pageIndex, setPageIndex] = React.useState(0)

    React.useEffect(() => {
        POSTS.getBySearch({ per_page: 5, categories: props.category })
            .then(([postData, _]) => {
                setPosts(posts => posts.concat(postData))
                setPageIndex(index => index + 1)
            })
    }, [])

    return (
        <section style={styles} >
            {
                posts.map(post => (
                    <CardLink title={post.title} postID={post.id}></CardLink>
                ))
            }
        </section>
    )
}

const styles = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(1fr)',
    gap: '1rem',
    padding: '2rem'
}
