import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons

// core components
import Parallax from "components/Parallax/Parallax.js"

import styles from "assets/jss/material-kit-react/views/profilePage.js"
import { useLanguage } from "services/language"
import { POSTS } from "services/wordpress"
import { CardLink } from "components/CardLink/CardLink"
import { Button } from "@material-ui/core"
import { useLocation } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function ExplorePage() {
  const classes = useStyles()
  const location = useLocation()
  const { posts, page, pageCount, setPage, setSearch } = useSearchPosts(
    new URLSearchParams(window.location.search).get('search')
  )

  React.useEffect(() => {
    setSearch(new URLSearchParams(window.location.search).get('search'))
    setPage(1)
  }, [location])

  return (
    <div>
      <Parallax small filter image={require("assets/img/Events.JPG").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div>
            <h3>{page}/{pageCount}</h3>
            <Button onClick={() => setPage(page => page - 1)} disabled={page <= 1}>Prev</Button>
            <Button onClick={() => setPage(page => page + 1)} disabled={page >= pageCount}>Next</Button>
          </div>
          <section style={sectionStyles}>
            {
              posts.map((post, i) => <CardLink key={i} postID={post.id} title={post.title} />)
            }
          </section>
        </div>
      </div>
    </div>
  )
}

const sectionStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'repeat(1fr)',
  gap: '1rem',
  padding: '2rem'
}

function useSearchPosts(searchTerm) {
  const { language } = useLanguage()
  const [posts, setPosts] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [search, setSearch] = React.useState(searchTerm)
  const [page, setPage] = React.useState(1)
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    POSTS.getBySearch({ per_page: 5, page, search })
      .then(([posts, pageCount]) => {
        setPosts(posts)
        setPageCount(pageCount)
      })
      .then(() => setLoaded(true))
  }, [language, page, search])

  return { posts, setPosts, page, setPage, loaded, search, setSearch, pageCount }
}
