/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { FooterSponsors } from "./FooterSponsors";
import { LANGUAGE, useLanguage } from 'services/language'

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Pic from "../../assets/img/DCCC_Logo.jpg";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

const localization = {
  sponsorText: {
    [LANGUAGE.Chinese]: "感謝我們的贊助單位",
    [LANGUAGE.English]: "Thank you to our sponsors"
  },
}

export default function Footer(props) {
  const classes = useStyles();

  const { language } = useLanguage()

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <p align="left">Dallas Chinese Community Center <br></br>
            400 N Greenville Ave #12 • Richardson • TX 75081<br></br>
            972-480-0311<br></br>
            info@dallasccc.org<br></br>
          </p>

        </div>
        <div className={classes.right}>
          {localization.sponsorText[language]}
          <FooterSponsors></FooterSponsors>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
