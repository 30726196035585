import React from "react"
import ReactDOM from "react-dom"
import { createBrowserHistory } from "history"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import "assets/scss/material-kit-react.scss?v=1.9.0"

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js"
import ProfilePage from "views/ProfilePage/ProfilePage.js"
import LoginPage from "views/LoginPage/LoginPage.js"
import EventsPage from "views/EventsPage/EventsPage.js"
import AboutPage from "views/AboutPage/AboutPage.js"
import SupportPage from "views/SupportPage/SupportPage.js"
import ResourcesPage from "views/ResourcesPage/ResourcesPage.js"
import ContactPage from "views/ContactPage/ContactPage.js"
import HealthWellness from "views/LandingPage/Services/HealthWellness.js"
import ArtsCulture from "views/LandingPage/Services/ArtsCulture.js"
import Education from "views/LandingPage/Services/Education.js"
import EventInfo from "views/EventInfoPage/EventInfo.js"
import DCCCHeader from "views/Components/DCCCHeader"
import Footer from "components/Footer/Footer"
import ExplorePage from "views/ExplorePage/ExplorePage"

const hist = createBrowserHistory()

ReactDOM.render(
  <BrowserRouter history={hist}>
    <DCCCHeader />
    <div style={{ minHeight: '100vh' }}>
      <Switch>
        <Route path="/profile" component={ProfilePage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/events" component={EventsPage} />
        <Route path="/event-info" component={EventInfo} />
        <Route path="/explore" component={ExplorePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/support" component={SupportPage} />
        <Route path="/resources" component={ResourcesPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/health-and-wellness" component={HealthWellness} />
        <Route path="/arts-and-culture" component={ArtsCulture} />
        <Route path="/education" component={Education} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </div>
    <Footer />
  </BrowserRouter>,
  document.getElementById("root")
)
