import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { useLocation } from "react-router-dom";
import { PostComponent } from "views/pageutils";

const useStyles = makeStyles(styles);

export default function EventsInfo() {
  const classes = useStyles();
  // watch the url for changes
  const location = useLocation()
  // when a url change happens, grab the page from the query params 
  const [page, setPage] = React.useState(0);
  React.useEffect(() => setPage(+new URLSearchParams(window.location.search).get('id')), [location])
  // track the page in a useState

  return (
    <div>
      <Parallax small filter image={require("assets/img/Events.JPG").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container} style={{ padding: "20px" }}>
            <GridContainer justify="center">
              {
                page ? <PostComponent postID={page} /> : null
              }
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}