import React from "react";
import { useLanguage } from "services/language";
import { PAGES, POSTS } from "../services/wordpress";

export function PageComponent({ htmlBody, pageID }) {

  const { language } = useLanguage()

  React.useEffect(() => {
    if (htmlBody !== undefined) {
      setHtml(htmlBody)
    } else if (pageID !== undefined) {
      PAGES.getById(pageID).then(html => {
        setHtml(html.content)
        setTitle(html.title)
      })
    } else {
      console.error("nothing provided to render")
    }
  }, [language])

  const [html, setHtml] = React.useState('')
  const [title, setTitle] = React.useState('')

  return <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center" }}>
    <h1>{title}</h1>
    <div dangerouslySetInnerHTML={{ __html: html }} style={{ maxWidth: "65%", margin: 'auto' }} />
  </div>
}

export function PostComponent({ htmlBody, postID }) {

  const { language } = useLanguage()

  React.useEffect(() => {
    if (htmlBody !== undefined) {
      setHtml(htmlBody)
    } else if (postID !== undefined) {
      POSTS.getById(postID).then(html => {
        setHtml(html.content)
        setTitle(html.title)
      })
    } else {
      console.error("nothing provided to render")
    }
  }, [language])

  const [html, setHtml] = React.useState('')
  const [title, setTitle] = React.useState('')

  return <div style={{ display: 'flex', flexDirection: 'column', }}>
    <h1>{title}</h1>
    <div dangerouslySetInnerHTML={{ __html: html }} style={{ maxWidth: "65%", margin: 'auto', textAlign: "left" }} />
  </div>
}