import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// images to use
import poster from "assets/img/DCCC-landing-bg.jpg";

// Sections for this page
import ServicesSection from "./Sections/ServicesSection.js";
import PhotoSection from "./Sections/PhotoSection.js";
import EventsSection from "./Sections/EventsSection.js";

import { LANGUAGE, useLanguage } from 'services/language'

const useStyles = makeStyles(styles);

const localisation = {
  welcomeText: {
    [LANGUAGE.Chinese]: "歡迎光臨達拉斯華人活動中心",
    [LANGUAGE.English]: "Welcome to Dallas Chinese Community Center"
  },
  missionStatement: {
    [LANGUAGE.English]: "Dallas Chinese Community Center provides a venue for Chinese people from around the world to renew their heritage in Chinese culture and education and to enjoy recreational activities. The Center provides a window of opportunity to share Chinese experiences with the DFW community. DCCC is dedicated to serve both the Asian and non-Asian communities, cultivating mutual understanding and establishing common values between Chinese and mainstream society and elevating the visibility and standing of Chinese Americans in the United States.",
    [LANGUAGE.Chinese]: "中心為達福地區所有來自世界各地的華人提供一個傳承中華文化、教育、及休閒的場所，並成為與主流社會互相交流的窗口。其任務以服務華人及社區為主，同時培養華人對主流社會的認知和建立與其他族裔群體的共識，並提高華人在美國社會的地位和能見度。",
  }
}

export default function LandingPage() {
  const classes = useStyles()

  const { language } = useLanguage()

  return (
    <div>
      <Parallax filter image={poster}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} >
              <h1 className={classes.title} style={{ width: "1200px" }}>{localisation.welcomeText[language]}</h1>
              <br />
            </GridItem>
            <GridItem>
              <h4>
                {localisation.missionStatement[language]}
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ServicesSection />
          <EventsSection />
          <PhotoSection />
        </div>
      </div>
    </div>
  );
}
