import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { LANGUAGE, useLanguage } from '../../services/language';

const localization = {
  languageText: {
    [LANGUAGE.Chinese]: "語言",
    [LANGUAGE.English]: "Language"
  },

}

const useStyles = makeStyles({
  root: {
    paddingLeft: "32px",
  },
  legendStyle: {
    color: "#fff !important",
  },
  radioText: {
    color: "inherit",
    marginBottom: "-15px",
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#696969',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
})

function StyledRadio(props) {
  const classes = useStyles()

  return (
    <Radio
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

export default function RadioButtonsGroup() {
  const { language, setLanguage } = useLanguage()
  const classes = useStyles()

  const updateLanuage = (event) => setLanguage(event.target.value)

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend" className={classes.legendStyle}>{localization.languageText[language]}</FormLabel>
      <RadioGroup aria-label="language" name="language1" value={language} onChange={updateLanuage}>
        <FormControlLabel value={LANGUAGE.English} control={<StyledRadio />} label="English" className={classes.radioText} />
        <FormControlLabel value={LANGUAGE.Chinese} control={<StyledRadio />} label="中文" className={classes.radioText} />
      </RadioGroup>
    </FormControl>
  );
}